import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Image, Pressable, TextInput, Switch } from 'react-native';
import { Primary, Secondary, color2, color1, Width } from '../../Utility/Utility';
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import rightArrow from '../../Assets/Images/right-arrow-white.png';
import rightArrowBlack from '../../Assets/Images/right-arrow-black.png';
import { useTheme } from "../../Contexts/ThemeProvider";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import Toast from 'react-native-toast-message';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const Setting = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()
    const [isSingleTrade, setIsSingleTrade] = useState(false);
    const [isSoundAlert, setIsSoundAlert] = useState(false);
    const [isNotification, setIsNotification] = useState(false);

    useEffect(() => {
        check()
        getUserData()
    }, [])

    const check = async () => {
        await AsyncStorage.getItem('TRADE')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setIsSingleTrade(data)
                } else {
                }
            })
            .catch(error => {
            });
        await AsyncStorage.getItem('SOUND')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setIsSoundAlert(data)
                } else {
                }
            })
            .catch(error => {
            });
        await AsyncStorage.getItem('NOTIFICATION')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setIsNotification(data)
                    getUserData()
                } else {
                }
            })
            .catch(error => {
            });
    }

    const getUserData = () => {
        axios.get(`${BASE_URL}/me`)
            .then((res) => {
                console.log("Res", res.data)
                if (res.data) {
                    setIsSingleTrade(res?.data?.is_trade == 0 ? false : true)
                    setIsSoundAlert(res?.data?.is_sound == 0 ? false : true)
                    setIsNotification(res?.data?.is_notification == 0 ? false : true)
                }
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const toggleSwitch = async (value) => {
        if (value == 'TRADE') {
            setIsSingleTrade(!isSingleTrade)
            try {
                const jsonValue = JSON.stringify(!isSingleTrade)
                await AsyncStorage.setItem('TRADE', jsonValue)
            } catch (e) {
                // saving error
            }
        } else if (value == 'SOUND_ALERT') {
            setIsSoundAlert(!isSoundAlert)
            try {
                const jsonValue = JSON.stringify(!isSoundAlert)
                await AsyncStorage.setItem('SOUND', jsonValue)
            } catch (e) {
                // saving error
            }
        } else if (value == 'NOTIFICATION') {
            setIsNotification(!isNotification)
            try {
                const jsonValue = JSON.stringify(!isSoundAlert)
                await AsyncStorage.setItem('NOTIFICATION', jsonValue)
            } catch (e) {
                // saving error
            }
        }
        console.log("adf", {
            is_trade: value == 'TRADE' ? !isSingleTrade : isSingleTrade,
            is_sound: value == 'SOUND_ALERT' ? !isSoundAlert : isSoundAlert,
            is_notification: value == 'NOTIFICATION' ? !isNotification : isNotification
        })
        axios.put(`${BASE_URL}/update-app-setting`, {
            is_trade: value == 'TRADE' ? !isSingleTrade : isSingleTrade,
            is_sound: value == 'SOUND_ALERT' ? !isSoundAlert : isSoundAlert,
            is_notification: value == 'NOTIFICATION' ? !isNotification : isNotification
        })
            .then((res) => {
                // Toast.show({
                //     type: 'success',
                //     text1: res?.data?.message,
                // });
            })
            .catch((e) => {

            })
    }

    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Settings</Text>
                    </View>
                </View>
                <View>
                    <Pressable style={[styles.item, { borderColor: colors.Secondary }]}>
                        <Text style={[styles.titleText, { color: colors.Secondary }]}>Single Click Trade</Text>
                        <Switch
                            trackColor={{ false: '#767577', true: '#b8e2ff' }}
                            thumbColor={isSingleTrade ? '#0084df' : '#f4f3f4'}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={() => toggleSwitch('TRADE')}
                            value={isSingleTrade}
                        />
                    </Pressable>
                    <Pressable style={[styles.item, { borderColor: colors.Secondary }]}>
                        <Text style={[styles.titleText, { color: colors.Secondary }]}>Sound Alert</Text>
                        <Switch
                            trackColor={{ false: '#767577', true: '#b8e2ff' }}
                            thumbColor={isSoundAlert ? '#0084df' : '#f4f3f4'}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={() => toggleSwitch('SOUND_ALERT')}
                            value={isSoundAlert}
                        />
                    </Pressable>
                    <Pressable style={[styles.item, { borderColor: colors.Secondary }]}>
                        <Text style={[styles.titleText, { color: colors.Secondary }]}>Notifications</Text>
                        <Switch
                            trackColor={{ false: '#767577', true: '#b8e2ff' }}
                            thumbColor={isNotification ? '#0084df' : '#f4f3f4'}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={() => toggleSwitch('NOTIFICATION')}
                            value={isNotification}
                        />
                    </Pressable>
                </View>
            </View>
        </>
    )
}

export default Setting;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    headerTitle: {
        fontSize: 16,
    },
    titleView: {
        alignItems: 'center',
        width: '90%'
    },
    titleText: {
        marginLeft: 30
    },
    item: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        padding: 12
    }
})