import React, { useEffect, useRef, useState } from "react";
import {
    View,
    StyleSheet,
    StatusBar,
    Text,
    TextInput,
    Pressable,
    ActivityIndicator,
    ImageBackground,
} from 'react-native';
import { Image } from 'expo-image';
import { color1, color2, color3, Primary, Secondary, Width } from "../../Utility/Utility";
import { LinearGradient } from 'expo-linear-gradient';
import axios from "axios";
import { BASE_URL } from "../../Config";
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_ERROR, AUTH_SERVICE_RUNNING, SET_USER, SET_LOGIN_SUCCESS } from "../../Actions/Types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTheme } from "../../Contexts/ThemeProvider";
import loginImage from '../../Assets/Images/loginImage.jpg';
import { resetAuth } from "../../Actions/AuthActions";
import passwordShow from "../../Assets/Images/eye.png";
import passwordHide from "../../Assets/Images/hide.png";
import { Audio } from 'expo-av';
import Toast from 'react-native-toast-message';
// import messaging from '@react-native-firebase/messaging';

const Login = ({ navigation, route, props }) => {
    const { dark, colors } = useTheme();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [serverCode, setServerCode] = useState('')
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const { data, isSelect } = route.params
    const [isShow, setIsShow] = useState(true)
    const checkSoundAlert = useRef()

    useEffect(() => {
        if (data) {
            setUsername(data.code)
            setPassword(data.password)
            setServerCode(data.server_code.toString())

            setTimeout(() => {
                onLogin(data.code, data.password, data.server_code)
            }, 1000);
        }
        checkOtherOptions()
    }, [])

    useEffect(() => {
        Audio.setAudioModeAsync({
            staysActiveInBackground: true,
            playsInSilentModeIOS: true,
        });
    }, []);

    const checkOtherOptions = async () => {
        await AsyncStorage.getItem('SOUND')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    checkSoundAlert.current = data
                } else {
                }
            })
            .catch(error => {
            });
    }

    const onPlaySound = async () => {
        const { sound } = await Audio.Sound.createAsync(
            require('../../Assets/sound/login_sound.mp3'),
            undefined,
            null,
            false
        );
        await sound.playAsync();
        setTimeout(() => {
            sound.unloadAsync();
        }, 10000)
    }


    const onLogin = (user, pass, code) => {
        axios.post(`${BASE_URL}/ouath-token`, {
            code: user ? user : username,
            password: pass ? pass : password,
            server_code: code ? code : serverCode,
            token: '',
            type: 'ANDROID'
        })
            .then((res) => {
                dispatch({ type: AUTH_SERVICE_RUNNING })
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${res.data.access_token}`
                }
                axios.get(`${BASE_URL}/me`, {
                    headers: headers
                })
                    .then(async (val) => {
                        if (val.data.role == 'USER') {
                            if (window.ReactNativeWebView) {
                                window.ReactNativeWebView.postMessage(
                                    val.data.id
                                );
                            }
                            const jsonValue = JSON.stringify(val.data)
                            await AsyncStorage.setItem('user_detail', jsonValue)
                            await AsyncStorage.getItem('login_users')
                                .then(req => JSON.parse(req))
                                .then(async data => {

                                    if (data) {
                                        var array = data;

                                        if (isSelect == false) {
                                            array.push({ code: val.data.code, password: password, server_code: val.data.server_code, name: val.data.name })
                                            const logData = JSON.stringify(array)
                                            await AsyncStorage.setItem('login_users', logData)
                                                .then(json => console.log('success!'))
                                                .catch(error => console.log('error!'));
                                        }
                                        else {
                                            for (var i in data) {
                                                if (data[i].code == val.data.code) {
                                                    data[i].code = val.data.code;
                                                    data[i].server_code = val.data.server_code;
                                                    data[i].name = val.data.name;
                                                }
                                            }
                                            var newData = JSON.stringify(data)
                                            await AsyncStorage.setItem('login_users', newData)
                                                .then(json => console.log('success!'))
                                                .catch(error => console.log('error!'));

                                        }
                                    } else {
                                        const logData = JSON.stringify([{ code: val.data.code, password: password, server_code: val.data.server_code, name: val.data.name }])
                                        await AsyncStorage.setItem('login_users', logData)
                                            .then(json => console.log('success!'))
                                            .catch(error => console.log('error!'));
                                    }
                                    if (checkSoundAlert.current) {
                                        onPlaySound()
                                    }
                                })
                                .catch((error) =>
                                    console.log('error!')
                                );
                            dispatch({ type: SET_USER, payload: { access_token: res.data.access_token } })
                            dispatch({ type: SET_LOGIN_SUCCESS })
                        } else {
                            Toast.show({
                                type: 'error',
                                text1: "Invalid Credentials",
                            });
                            dispatch({ type: AUTH_ERROR, payload: { error: 'error' } })
                            dispatch(resetAuth())
                        }
                    })
                    .catch((e) => {
                        dispatch({ type: AUTH_ERROR, payload: { error: 'error' } })
                        dispatch(resetAuth())
                    })
            })
            .catch((e) => {
                console.log("e", e)
                Toast.show({
                    type: 'error',
                    text1: e.response.data.message,
                });
                dispatch({ type: AUTH_ERROR, payload: { error: 'error' } })
                dispatch(resetAuth())
            })
    }

    return (
        <>
            <StatusBar
                animated={true}
                backgroundColor={Primary}
            />
            <ImageBackground source={loginImage} style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={[styles.loginText, { color: colors.Primary, }]}>Login</Text>
                <View style={styles.mainView}>
                    <Text style={styles.label}>Account Code</Text>
                    <TextInput style={[styles.input, { color: colors.Secondary, backgroundColor: colors.color2 }]} placeholder='Enter your username' placeholderTextColor={'#D3D3D3'} onChangeText={text => setUsername(text)} value={username} />
                    <Text style={styles.label}>Password</Text>
                    <View style={[styles.input, { color: colors.Secondary, backgroundColor: colors.color2, height: 50, flexDirection: 'row' }]}>
                        <TextInput style={[{ color: colors.Secondary, width: Width * 0.6 }]} secureTextEntry={isShow} placeholder='Enter your password' placeholderTextColor={'#D3D3D3'} onChangeText={text => setPassword(text)} value={password} />
                        {
                            isShow ?
                                <Pressable onPress={() => setIsShow(false)}>
                                    <Image source={passwordShow} style={{ marginLeft: 18, marginTop: 15, height: 22, width: 22 }} />
                                </Pressable> :
                                <Pressable onPress={() => setIsShow(true)}>
                                    <Image source={passwordHide} style={{ marginLeft: 18, marginTop: 15, height: 22, width: 22 }} />
                                </Pressable>
                        }

                    </View>
                    <Text style={styles.label}>Server Code</Text>
                    <TextInput style={[styles.input, { color: colors.Secondary, backgroundColor: colors.color2 }]} placeholder='Enter your server code' placeholderTextColor={'#D3D3D3'} onChangeText={text => setServerCode(text)} value={serverCode} />
                    <Pressable onPress={() => onLogin()} disabled={auth.isLoading}>
                        <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#26D0CE', '#1A2980']} style={styles.loginButton}>
                            {auth.isLoading && <ActivityIndicator size="small" color="white" style={{ marginRight: 20 }} />}
                            <Text style={styles.loginButtonText}>Login</Text>
                        </LinearGradient>
                    </Pressable>
                </View>
            </ImageBackground>
            {/* </View> */}
        </>
    )
}

export default Login;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    loginText: {
        // fontFamily: 'Roboto-Medium',
        fontSize: 28
    },
    mainView: {
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        marginLeft: 40,
        marginTop: 70
    },
    label: {
        color: color1,
        fontSize: 15,
        fontFamily: 'Roboto-Regular',
        fontWeight: '600',
        marginTop: 17
    },
    input: {
        borderRadius: 12,
        width: Width * 0.8,
        marginTop: 15,
        paddingLeft: 15,
        height: 50
    },
    loginButton: {
        borderRadius: 12,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 30,
        flexDirection: 'row'
    },
    loginButtonText: {
        color: Secondary,
        // fontFamily: 'Roboto-Medium',
        fontSize: 20
    },
    signupView: {
        flexDirection: 'row',
        alignSelf: 'center',
        marginTop: 30
    },
    signupText: {
        color: color3,
        marginTop: 17,
        fontSize: 15,
        fontFamily: 'Roboto-Regular',
        fontWeight: '600',
    }
})