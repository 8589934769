import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { StyleSheet, View, Pressable, Text, FlatList, ActivityIndicator, Keyboard, Modal } from 'react-native';
import { Width, color1 } from '../../Utility/Utility';
import MenuWhiteIcon from '../../Assets/Images/menu-white.png';
import MenuBlackIcon from '../../Assets/Images/menu-black.png';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import RBSheet from "react-native-raw-bottom-sheet";
import { useTheme } from '../../Contexts/ThemeProvider';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SearchIcon from '../../Assets/Images/search-icon-white.png';
import SearchBlackIcon from '../../Assets/Images/searchBlue.png';
import crossIcon from '../../Assets/Images/cross-black.png';
import { LinearGradient } from 'expo-linear-gradient';
import moment from 'moment';
import { TextInput } from 'react-native-gesture-handler';
import { DrawerActions } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { Audio } from 'expo-av';
import { Image } from 'expo-image';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import webstomp from "webstomp-client";
import { useNavigation } from '@react-navigation/native';
import Spinner from 'react-native-loading-spinner-overlay';
import { RefreshControl } from 'react-native-web-refresh-control'
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const Positions = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const [selectedTab, setSelectedTab] = useState('NET')
    const [selectedSegment, setSelectedSegment] = useState('NFO')
    const [isLoading, setLoading] = useState(false)
    const [allPositions, setAllPositions] = useState([])
    const refRBSheet = useRef();
    const tradeSheet = useRef();
    const token = useRef();
    const [userDetail, setUserDetail] = useState()
    const [showSearch, setShowSearch] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisibleTrade, setModalVisibleTrade] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [date, setDate] = useState(new Date())
    const [enddate, setEndDate] = useState(new Date())
    const userId = useRef('')
    const isRollShow = useRef('')
    const selectedType = useRef();
    const [selectedData, setSelectedData] = useState()
    const [livePosition, setLivePosition] = useState(0)
    const [lot, setLot] = useState('1')
    const [quantity, setQuantity] = useState()
    const [marketType, setMarketType] = useState('MARKET')
    const [price, setPrice] = useState('')
    const selectedIndex = useRef();
    const [checkShowModal, setCheckShowModal] = useState(false)
    const [checkSoundAlert, setCheckSoundAlert] = useState(false)
    const selectedTrade = useRef('');
    const [buttonLoader, setButtonLoader] = useState(false)
    const live = useRef([]);
    const client = useRef();
    const [isFocused, setIsFocused] = useState(false);
    const navigation = useNavigation();
    const [isSpinner, setIsSpinner] = useState(false)
    const [marginData, setMarginData] = useState()


    const _onMenuPress = () => {
        props.navigation.dispatch(DrawerActions.openDrawer());
    }

    useEffect(() => {
        getUserDetail()
        checkTab()
        checkOtherOptions()
    }, [isFocused])

    useEffect(() => {
        const unsubscribeFocus = navigation.addListener('focus', () => {
            setIsFocused(true);
        });
        const unsubscribeBlur = navigation.addListener('blur', () => {
            setIsFocused(false);
        });

        return () => {
            unsubscribeFocus();
            unsubscribeBlur();
        };
    }, []);

    useEffect(() => {
        Audio.setAudioModeAsync({
            staysActiveInBackground: true,
            playsInSilentModeIOS: true,
        });
    }, []);

    useEffect(() => {
        connect();
    }, [isFocused])

    const connect = () => {
        var url = "wss://easytrade9.com:15673/ws";
        var login = "market";
        var password = "EasyTrade";
        if (!client.current || !client.current.connected) {
            client.current = webstomp.client(url, { debug: false });
            client.current.connect(login, password, () => {
                client.current.subscribe("/exchange/market-data", (message) => {
                    onMessage(JSON.parse(message.body))
                });
            });
        }
    }

    const checkTab = async () => {
        await AsyncStorage.getItem('tab')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setSelectedSegment(data.currenTab)
                    getData(data.currenTab)
                } else {
                    getData('NFO')
                }
            })
            .catch(error => {
                getData('NFO')
            });
    }

    const checkOtherOptions = async () => {
        await AsyncStorage.getItem('TRADE')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setCheckShowModal(data)
                } else {
                }
            })
            .catch(error => {
            });
        await AsyncStorage.getItem('SOUND')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setCheckSoundAlert(data)
                } else {
                }
            })
            .catch(error => {
            });
    }

    const getData = (tab, search, fromDate, toDate, subTab) => {
        var fDate = fromDate ? moment(fromDate).format('YYYY-MM-DD') : ''
        var tDate = toDate ? moment(toDate).format('YYYY-MM-DD') : ''
        setLoading(true)
        axios.get(`${BASE_URL}/position-report-list?exchange=${tab}&day=${subTab == 'DAY' ? 'TODAY' : ''}&keyword=${search ? search : ''}&from_date=${fDate}&to_date=${tDate}`)
            .then((res) => {
                setLoading(false)
                var value = 0;
                for (var i in res.data) {
                    if (res.data[i].net_qty != 0) {
                        var avg = ((res.data[i].total_sell_quantity * res.data[i].avg_sell_price) - (res.data[i].total_buy_quantity * res.data[i].avg_buy_price)) / res.data[i].net_qty;
                        res.data[i].bea = Math.abs(avg);
                        if (res.data[i].net_qty != 0) {
                            res.data[i].m2m = (res.data[i].ltp - res.data[i].bea) * res.data[i].net_qty
                        } else {
                            res.data[i].m2m = (res.data[i].total_sell_quantity * res.data[i].avg_sell_price) - (res.data[i].total_buy_quantity * res.data[i].avg_buy_price)
                        }
                    }
                    value += res.data[i].m2m != undefined && res.data[i].m2m
                }
                if (value) {
                    setLivePosition(value)
                }
                live.current = res.data
                setAllPositions(res.data)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const getUserDetail = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            userId.current = data.id
            setUserDetail(data)
            getMargin(data.id)
        } catch (e) {
            // error reading value
        }
    }

    const getMargin = (id) => {
        axios.get(`${BASE_URL}/client-summary-report/${id}`)
            .then((res) => {
                setMarginData(res.data)
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onClosePosition = () => {
        refRBSheet.current.close()
        setIsSpinner(true)
        axios.post(`${BASE_URL}/close-position`, {
            device: 'MOBILE',
            close_position_array: [
                {
                    instrument_token: token.current,
                    user_id: userDetail.id
                }
            ]
        })
            .then((res) => {
                setIsSpinner(false)
                Toast.show({
                    type: 'success',
                    text1: res.data.message,
                });
                getData(selectedSegment, '', '', '', selectedTab)
            })
            .catch((e) => {
                setIsSpinner(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onrollover = () => {
        refRBSheet.current.close()
        setIsSpinner(true)
        axios.post(`${BASE_URL}/roll-over`, {
            device: 'MOBILE',
            close_position_array: [
                {
                    instrument_token: token.current,
                    user_id: userDetail.id,
                    exchange: selectedData.exchange
                }
            ]
        })
            .then((res) => {
                setIsSpinner(false)
                Toast.show({
                    type: 'success',
                    text1: res.data.message,
                });
                getData(selectedSegment)
            })
            .catch((e) => {
                setIsSpinner(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onChangeTab = async (value) => {
        const data = JSON.stringify({ currenTab: value })
        await AsyncStorage.setItem('tab', data)
        setSelectedSegment(value)
        getData(value, '', '', '', selectedTab)
    }

    var isRunning = false;
    const onMessage = (data) => {
        if (!isRunning) {
            if (live.current.length > 0 && data.length > 0) { }
            isRunning = true;
            var value = 0;
            var isUpdate = false;
            for (var j in live.current) {
                for (var i in data) {
                    if (live.current[j].instrument_token == data[i].it) {
                        live.current[j].a = data[i].a.toFixed(2);
                        live.current[j].b = data[i].b.toFixed(2);
                        live.current[j].ac = data[i].ac;
                        live.current[j].bc = data[i].bc;
                        live.current[j].ltpc = data[i].ltpc;
                        live.current[j].c = data[i].c;
                        live.current[j].ltp = data[i].ltp.toFixed(2);
                        live.current[j].it = data[i].it;
                        live.current[j].ch = data[i].ch;
                        live.current[j].h = data[i].h.toFixed(2);
                        live.current[j].l = data[i].l.toFixed(2);
                        live.current[j].o = data[i].o;
                        live.current[j].n = data[i].n;
                        live.current[j].ul = data[i].ul;
                        live.current[j].ll = data[i].ll;
                        live.current[j].ba = data[i].ba;
                        isUpdate = true;
                        live.current[j].ltp = data[i].ltp.toFixed(2);
                        // allPositions[j].tick_size = data[i].tick_size;
                        if (live.current[j].net_qty != 0) {
                            live.current[j].m2m = (live.current[j].ltp - live.current[j].bea) * live.current[j].net_qty
                        } else {
                            live.current[j].m2m = (live.current[j].total_sell_quantity * live.current[j].avg_sell_price) - (live.current[j].total_buy_quantity * live.current[j].avg_buy_price)
                        }

                    }
                }

                value += live.current[j].m2m != undefined && live.current[j].m2m
            }

            if (isUpdate) {
                setLivePosition(value)
                setAllPositions([...live.current])
            }

            isRunning = false;
        }

    }

    const onSubmit = (status) => {
        if (buttonLoader || isSpinner) {
            return;
        }
        setButtonLoader(true)
        setIsSpinner(true)
        setModalVisibleTrade(false)
        var priceValue = '';
        var reject_reason = '';
        if (status == 'BUY' && marketType == 'MARKET') {
            priceValue = allPositions[selectedIndex.current].a
        } else if (status == 'SELL' && marketType == 'MARKET') {
            priceValue = allPositions[selectedIndex.current].b
        } else if (marketType == 'LIMIT' || marketType == 'STOPLOSS') {
            priceValue = price
        }

        // if (
        //     Number(price) < Number(allPositions[selectedIndex.current].ll) &&
        //     marketType != "MARKET" && marketType != "INTRADAY"
        // ) {
        //     Toast.show({
        //         type: 'error',
        //         text1: `price should be greater then lower limit`,
        //         text2: `${allPositions[selectedIndex.current].ll}. `,
        //         visibilityTime: 5000
        //     });
        //     var reject_reason =
        //         "price should be greater then lower limit " +
        //         allPositions[selectedIndex.current].ll +
        //         ".";
        // }

        // if (Number(price) > Number(allPositions[selectedIndex.current].ul) && marketType != "INTRADAY") {
        //     Toast.show({
        //         type: 'error',
        //         text1: `price should be less then upper limit`,
        //         text2: `${allPositions[selectedIndex.current].ul}. `,
        //     });
        //     var reject_reason =
        //         "price should be less then upper limit " +
        //         allPositions[selectedIndex.current].ul +
        //         ".";
        // }


        // if (
        //     status == "BUY" &&
        //     marketType == "STOPLOSS"
        // ) {
        //     if (
        //         price <= allPositions[selectedIndex.current].ltp &&
        //         price <= allPositions[selectedIndex.current].a
        //     ) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Stoploss price should be greater then ltp price.`,
        //         });
        //         var reject_reason =
        //             "Stoploss price should be greater then ltp price.";
        //     }
        // }

        // if (
        //     status == "SELL" &&
        //     marketType == "STOPLOSS"
        // ) {
        //     if (
        //         price >= allPositions[selectedIndex.current].ltp &&
        //         price >= allPositions[selectedIndex.current].b
        //     ) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Stoploss price should be less then ltp price.`,
        //         });
        //         var reject_reason =
        //             "Stoploss price should be less then ltp price.";
        //     }
        // }


        // if (marketType == "LIMIT") {
        //     if (
        //         price >= allPositions[selectedIndex.current].b &&
        //         price <= allPositions[selectedIndex.current].a
        //     ) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Limit price should not be in between bid price and ask price.`,
        //         });
        //         var reject_reason =
        //             "Limit price should not be in between bid price and ask price.";
        //     }
        // }

        // if (
        //     marketType == "LIMIT" &&
        //     status == "BUY"
        // ) {
        //     if (price > allPositions[selectedIndex.current].ltp) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Limit price should be Less then LTP.`,
        //         });
        //         var reject_reason = "Limit price should be Less then LTP.";
        //     }
        // }


        // if (
        //     marketType == "LIMIT" &&
        //     status == "SELL"
        // ) {
        //     if (price < allPositions[selectedIndex.current].ltp) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Limit price should be greater then LTP.`,
        //         });
        //         var reject_reason = "Limit price should be greater then LTP.";
        //     }
        // }

        if (marketType == 'LIMIT' || marketType == 'STOPLOSS') {
            var data = (priceValue / allPositions[selectedIndex.current]?.tick_size).toFixed(2) % 1 == 0
            if (priceValue == '' || data == false) {
                if (priceValue == '') {
                    Toast.show({
                        type: 'error',
                        text1: "Please enter price.",
                    });
                } else if (data == false) {
                    Toast.show({
                        type: 'error',
                        text1: ` Price Should be in multiple of ${allPositions[selectedIndex.current]?.tick_size}`,
                    });
                }

            } else {
                setIsSpinner(true)
                setButtonLoader(true)
                axios.post(`${BASE_URL}/add-trade`, {
                    a: allPositions[selectedIndex.current].a,
                    ac: allPositions[selectedIndex.current].ac,
                    b: allPositions[selectedIndex.current].b,
                    bc: allPositions[selectedIndex.current].bc,
                    c: allPositions[selectedIndex.current].c,
                    ch: allPositions[selectedIndex.current].ch,
                    device: 'MOBILE',
                    exchange: allPositions[selectedIndex.current].exchange,
                    h: allPositions[selectedIndex.current].h,
                    instrument_token: allPositions[selectedIndex.current].instrument_token,
                    l: allPositions[selectedIndex.current].l,
                    lot_size: lot,
                    ltp: allPositions[selectedIndex.current].ltp,
                    ltpc: allPositions[selectedIndex.current].ltpc,
                    n: allPositions[selectedIndex.current].n,
                    name: allPositions[selectedIndex.current].name,
                    o: allPositions[selectedIndex.current].o,
                    order_action: status,
                    order_type: marketType,
                    price: priceValue,
                    quantity: quantity,
                    trading_symbol: allPositions[selectedIndex.current].name + " " + moment(allPositions[selectedIndex.current].expiry).format('DDMMM').toUpperCase(),
                    user_id: userDetail.id,
                    reject_reason: reject_reason ? reject_reason : '',
                    ba: allPositions[selectedIndex.current].ba
                })
                    .then(async (res) => {
                        console.log("res?.data?.trade_obj?.price", res?.data?.trade_obj)
                        setIsSpinner(false)
                        setButtonLoader(false)
                        setModalVisibleTrade(false)
                        if (reject_reason) {
                            if (checkSoundAlert) {
                                onPlayErrorSound()
                            }
                        } else {
                            if (status == 'SELL') {
                                Toast.show({
                                    type: 'error',
                                    text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                    text2: marketType != 'INTRADAY' && `${allPositions[selectedIndex.current].name} ${allPositions[selectedIndex.current].expiry && moment(allPositions[selectedIndex.current].expiry).format('DDMMM').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj.price ? res?.data?.trade_obj.price : priceValue}. `,
                                });
                            } else {
                                Toast.show({
                                    type: 'success',
                                    text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                    text2: marketType != 'INTRADAY' && `${allPositions[selectedIndex.current].name} ${allPositions[selectedIndex.current].expiry && moment(allPositions[selectedIndex.current].expiry).format('DDMMM').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj.price ? res?.data?.trade_obj.price : priceValue}. `,
                                });
                            }
                            if (checkSoundAlert) {
                                onPlaySuccessSound()
                            }
                        }
                        setMarketType('MARKET')
                        setPrice('')
                        setQuantity('')
                        setLot('')
                        getData(selectedSegment)
                    })
                    .catch(async (e) => {
                        setIsSpinner(false)
                        setModalVisibleTrade(false)
                        setButtonLoader(false)
                        console.log("e?.response?.data?.message", e?.response?.data)
                        Toast.show({
                            type: 'error',
                            text1: e?.response?.data?.message,
                        });
                        if (checkSoundAlert) {
                            onPlayErrorSound()
                        }
                        if (e.response.status == 401) {
                            dispatch(resetAuth())
                        }
                    })
            }
        } else {
            setIsSpinner(true)
            setButtonLoader(true)
            axios.post(`${BASE_URL}/add-trade`, {
                a: allPositions[selectedIndex.current].a,
                ac: allPositions[selectedIndex.current].ac,
                b: allPositions[selectedIndex.current].b,
                bc: allPositions[selectedIndex.current].bc,
                c: allPositions[selectedIndex.current].c,
                ch: allPositions[selectedIndex.current].ch,
                device: 'MOBILE',
                exchange: allPositions[selectedIndex.current].exchange,
                h: allPositions[selectedIndex.current].h,
                instrument_token: allPositions[selectedIndex.current].instrument_token,
                l: allPositions[selectedIndex.current].l,
                lot_size: lot,
                ltp: allPositions[selectedIndex.current].ltp,
                ltpc: allPositions[selectedIndex.current].ltpc,
                n: allPositions[selectedIndex.current].n,
                name: allPositions[selectedIndex.current].name,
                o: allPositions[selectedIndex.current].o,
                order_action: status,
                order_type: marketType,
                price: priceValue,
                quantity: quantity,
                trading_symbol: allPositions[selectedIndex.current].name + " " + moment(allPositions[selectedIndex.current].expiry).format('DDMMM').toUpperCase(),
                user_id: userDetail.id,
                reject_reason: reject_reason ? reject_reason : '',
                ba: allPositions[selectedIndex.current].ba
            })
                .then(async (res) => {
                    console.log("res?.data?.trade_obj?.price", res?.data?.trade_obj)
                    setIsSpinner(false)
                    refRBSheet.current.close()
                    setMarketType('MARKET')
                    setPrice('')
                    setQuantity('')
                    setLot('')
                    setButtonLoader(false)
                    setModalVisibleTrade(false)
                    if (reject_reason) {
                        if (checkSoundAlert) {
                            onPlayErrorSound()
                        }
                    } else {
                        if (status == 'SELL') {
                            Toast.show({
                                type: 'error',
                                text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                text2: marketType != 'INTRADAY' && `${allPositions[selectedIndex.current].name} ${allPositions[selectedIndex.current].expiry && moment(allPositions[selectedIndex.current].expiry).format('DDMMM').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj.price ? res?.data?.trade_obj.price : priceValue}. `,
                            });
                        } else {
                            Toast.show({
                                type: 'success',
                                text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                text2: marketType != 'INTRADAY' && `${allPositions[selectedIndex.current].name} ${allPositions[selectedIndex.current].expiry && moment(allPositions[selectedIndex.current].expiry).format('DDMMM').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj.price ? res?.data?.trade_obj.price : priceValue}. `,
                            });
                        }
                        if (checkSoundAlert) {
                            onPlaySuccessSound()
                        }
                    }

                    getData(selectedSegment)
                })
                .catch(async (e) => {
                    setIsSpinner(false)
                    setModalVisibleTrade(false)
                    setButtonLoader(false)
                    console.log("message", e?.response?.data)
                    Toast.show({
                        type: 'error',
                        text1: e?.response?.data?.message,
                    });
                    if (checkSoundAlert) {
                        onPlayErrorSound()
                    }
                    if (e.response.status == 401) {
                        dispatch(resetAuth())
                    }
                })
        }
    }

    const onChangingLot = (value) => {
        setLot(value)
        var lot = allPositions[selectedIndex.current].net_qty / allPositions[selectedIndex.current].net_lot
        setQuantity((value * lot).toString())
    }

    const onChangingQuantity = (value) => {
        setQuantity(value.replace(/[^0-9]/g, ''))
        var lot = allPositions[selectedIndex.current].net_qty / allPositions[selectedIndex.current].net_lot
        var data = (1 / lot * value)
        setLot(data.toFixed(2))
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button style={{ color: colors.Secondary, backgroundColor: '#b8e2ff', borderRadius: 12, height: 45, width: Width * 0.9, }} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const onPlaySuccessSound = async () => {
        const { sound } = await Audio.Sound.createAsync(
            require('../../Assets/sound/login_sound.mp3'),
            undefined,
            null,
            false
        );
        await sound.playAsync();
        setTimeout(() => {
            sound.unloadAsync();
        }, 10000)
    }

    const onPlayErrorSound = async () => {
        const { sound } = await Audio.Sound.createAsync(
            require('../../Assets/sound/errorNew.mp3'),
            undefined,
            null,
            false
        );
        await sound.playAsync();
        setTimeout(() => {
            sound.unloadAsync();
        }, 10000)
    }

    return (
        <>
            <Spinner
                visible={isSpinner}
                textContent={'Loading...'}
                textStyle={styles.spinnerTextStyle}
            />
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable onPress={() => _onMenuPress()}>
                            <Image source={dark ? MenuWhiteIcon : MenuBlackIcon} style={{ height: 25, width: 25 }} />
                        </Pressable>
                        <View>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={[styles.positionText, { color: colors.Secondary, }]}>Positions </Text>
                                {showSearch == false && <Text style={[styles.amount, { color: livePosition > 0 ? 'green' : 'red' }]}> ({livePosition && Number(livePosition).toFixed(2)})</Text>}
                            </View>
                            <View>
                                <Text style={{ lineHeight: 20, marginLeft: 20, marginBottom: 1 }}>Margin {selectedSegment == 'NFO' ? `${marginData?.nse_margin + " " + `(${marginData?.nse_bal})`}` : `${marginData?.mcx_margin + " " + `(${marginData?.mcx_bal})`}`}</Text>
                            </View>
                        </View>
                    </View>

                    <View>
                        {
                            showSearch == false &&
                            <View style={styles.headerIcon}>
                                <Pressable onPress={() => setShowSearch(true)}>
                                    <Image source={dark ? SearchIcon : SearchBlackIcon} style={styles.searchIcon} />
                                </Pressable>
                            </View>
                        }
                        {
                            showSearch == true &&
                            <View style={styles.headerIcon}>
                                <TextInput style={[styles.input, { color: colors.Secondary, backgroundColor: colors.color2 }]} placeholder='Search' placeholderTextColor={'#D3D3D3'} onChangeText={text => { setSearchText(text), getTrades(selectedSegment, text) }} value={searchText} />
                                <Pressable onPress={() => {
                                    setShowSearch(false)
                                    getData(selectedSegment)
                                }}>
                                    <Image source={crossIcon} style={styles.crossLogo} />
                                </Pressable>
                            </View>
                        }
                    </View>
                </View>
                <View style={{ backgroundColor: '#b8e2ff', paddingBottom: 10 }}>
                    <View style={styles.segmentTabView}>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'NFO' ? '#0084df' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('NFO') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'NFO' ? colors.Primary : colors.Secondary }]}>NSE</Text>
                        </Pressable>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'MCX' ? '#0084df' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('MCX') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'MCX' ? colors.Primary : colors.Secondary }]}>MCX</Text>
                        </Pressable>
                    </View>
                </View>
                <View style={styles.tabView}>
                    <Pressable style={[styles.tab, { borderBottomColor: selectedTab == 'DAY' ? colors.Secondary : colors.Primary }]} onPress={() => { setSelectedTab('DAY'), getData(selectedSegment, '', '', '', 'DAY') }}>
                        <Text style={{ color: colors.Secondary }}>DAY WISE</Text>
                    </Pressable>
                    <Pressable style={[styles.tab, { borderBottomColor: selectedTab == 'NET' ? colors.Secondary : colors.Primary }]} onPress={() => { setSelectedTab('NET'), getData(selectedSegment, '', '', '', 'NET') }}>
                        <Text style={{ color: colors.Secondary }}>NET WISE</Text>
                    </Pressable>
                </View>
                {isLoading && allPositions.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color='#000' />
                </View>}
                {!isLoading && allPositions.length == 0 && <View style={styles.loaderView}>
                    <Text style={{ color: colors.Secondary }}>No Scripts Found!</Text>
                </View>}
                {!isLoading && allPositions.length > 0 &&
                    <FlatList
                        data={allPositions}
                        renderItem={({ item, index }) => <Pressable key={new Date()} style={[styles.item, { borderBottomWidth: 1, borderColor: 'black', backgroundColor: item.net_qty < 0 ? '#edd3d3' : item.net_qty > 0 ? '#d9f2d9' : item.net_qty == 0 ? '#fff' : '' }]} onPress={() => {
                            // var value = moment(new Date()).format('YYYY-MM-DD')
                            // var d = moment(new Date(item.expiry), 'YYYY-MM-DD').subtract(1, 'd')
                            // var expiray = moment(d).format('YYYY-MM-DD')
                            // if (value == expiray) {
                            //     isRollShow.current = 1
                            // } else {
                            //     isRollShow.current = 0
                            // }
                            isRollShow.current = item.isRollOver
                            setSelectedData(item)
                            refRBSheet.current.open()
                            token.current = item.instrument_token
                            selectedIndex.current = index
                            var qty = Math.abs(item.net_qty)
                            var lot = Math.abs(item.net_lot)
                            setQuantity(qty.toString())
                            setLot(lot.toString())
                            setPrice('')
                            setMarketType('MARKET')
                        }}>
                            <View style={styles.nameView}>
                                <Text style={[styles.tradeName, { color: colors.Secondary }]}>{item.trading_symbol} (<Text style={{ color: item.net_qty > 0 ? "green" : item.net_qty < 0 ? 'red' : item.net_qty == 0 ? 'black' : '' }}>{item.net_qty}</Text>)</Text>
                                <Text style={[styles.tradeName, { color: item.m2m > 0 ? 'green' : 'red' }]}>{item.m2m && item.m2m.toFixed(2)}</Text>
                            </View>
                            <View style={styles.priceView}>
                                {
                                    item.order_action == 'SELL' ?
                                        <Text style={[styles.avgPrice, { color: colors.Secondary }]}>{item.avg_sell_price && (item.avg_sell_price).toFixed(2)} {`->`} {item.ltp}</Text>
                                        :
                                        <Text style={[styles.avgPrice, { color: colors.Secondary }]}>{item.avg_buy_price && (item.avg_buy_price).toFixed(2)} {`->`} {item.ltp}</Text>
                                }
                            </View>
                        </Pressable>}
                        keyExtractor={item => item.id}
                        refreshControl={
                            <RefreshControl refreshing={isLoading} onRefresh={() => { getData(selectedSegment) }} />
                        }
                    />
                }
            </View>
            <RBSheet
                ref={refRBSheet}
                closeOnDragDown={true}
                closeOnPressMask={true}
                height={130}
                customStyles={{
                    wrapper: {
                        backgroundColor: "transparent",
                    },
                    draggableIcon: {
                        backgroundColor: "#000",
                    },
                    container: {
                        borderTopRightRadius: 20,
                        borderTopLeftRadius: 20,
                        backgroundColor: '#b8e2ff'
                    }
                }}
            >
                <Text style={[styles.tradeName, { color: colors.Secondary, textAlign: 'center' }]}>{selectedData?.trading_symbol} (<Text style={{ color: selectedData?.net_qty > 0 ? "green" : 'red' }}>{selectedData?.net_qty}</Text>)</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 20 }} >
                    <Pressable style={{ backgroundColor: '#0084df', width: '30%', height: 40, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} onPress={() => {
                        refRBSheet.current.close()
                        props.navigation.navigate('PositionTrades', { token: token.current })
                    }}>
                        <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>Trades</Text>
                    </Pressable>
                    {
                        selectedData?.net_qty != 0 &&
                        <Pressable style={{ backgroundColor: '#0084df', width: '30%', height: 40, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} onPress={() => {
                            selectedType.current = 'CLOSE'
                            refRBSheet.current.close()
                            tradeSheet.current.open()
                        }}>
                            <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>Close Position</Text>
                        </Pressable>
                    }
                    {
                        isRollShow.current == '1' &&
                        <Pressable style={{ backgroundColor: '#0084df', width: '30%', height: 40, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} onPress={() => {
                            selectedType.current = 'ROLLOVER'
                            refRBSheet.current.close()
                            setConfirmModalVisible(true)
                        }}>
                            <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>Rollover</Text>
                        </Pressable>
                    }
                </View>
            </RBSheet>
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View style={{}}>
                            <Text style={{ color: colors.Secondary, fontSize: 18, fontWeight: 'bold', marginTop: 10 }}>Filter</Text>
                            <Text style={styles.label}>From Date</Text>
                            <DatePicker selected={date} onChange={(date) => setDate(date)} customInput={<ExampleCustomInput />} />
                            <Text style={styles.label}>To Date</Text>
                            <DatePicker selected={enddate} onChange={(date) => setEndDate(date)} customInput={<ExampleCustomInput />} />
                            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <Pressable style={styles.cancelButton} onPress={() => {
                                    setDate(new Date())
                                    setEndDate(new Date())
                                }}>
                                    <Text style={styles.cancelText}>Reset</Text>
                                </Pressable>
                                <Pressable style={styles.cancelButton} onPress={() => setModalVisible(!modalVisible)}>
                                    <Text style={styles.cancelText}>Cancel</Text>
                                </Pressable>
                                <Pressable onPress={() => { setModalVisible(false), getData(selectedSegment, searchText, date, enddate, selectedTab) }}>
                                    <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#26D0CE', '#1A2980']} style={styles.continueButton}>
                                        <Text style={styles.continueText}>Continue</Text>
                                    </LinearGradient>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
            <Modal animationType="slide" transparent={true} visible={confirmModalVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View >
                            <View style={{ backgroundColor: 'white', borderRadius: 20 }}>
                                <Pressable style={{ justifyContent: 'flex-end', alignSelf: 'flex-end', marginBottom: 10, marginTop: 18 }} onPress={() => setConfirmModalVisible(!confirmModalVisible)}>
                                    <Image source={crossIcon} style={styles.crossLogo} />
                                </Pressable>
                                <Text style={{ textAlign: 'center', fontSize: 15, color: colors.Secondary, fontWeight: '600' }}>Are you sure you want to place order.</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <Pressable style={styles.cancelButton} disabled={buttonLoader} onPress={() => setConfirmModalVisible(!confirmModalVisible)}>
                                        <Text style={styles.cancelText}>Cancel</Text>
                                    </Pressable>
                                    <Pressable
                                        disabled={buttonLoader}
                                        onPress={() => {
                                            if (selectedType.current == 'CLOSE') {
                                                setConfirmModalVisible(false)
                                                onClosePosition()
                                            } else if (selectedType.current == 'ROLLOVER') {
                                                setConfirmModalVisible(false)
                                                onrollover()
                                            }
                                        }}>
                                        <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#26D0CE', '#1A2980']} style={styles.continueButton}>
                                            <Text style={styles.continueText}>Continue</Text>
                                        </LinearGradient>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
            <RBSheet
                ref={tradeSheet}
                closeOnDragDown={true}
                closeOnPressMask={true}
                height={305}
                customStyles={{
                    wrapper: {
                        backgroundColor: "transparent",
                    },
                    draggableIcon: {
                        backgroundColor: "#000",
                    },
                    container: {
                        borderTopRightRadius: 20,
                        borderTopLeftRadius: 20,
                        backgroundColor: '#b8e2ff'
                    }
                }}
            >
                <View style={{ marginLeft: 10, flexDirection: 'row', justifyContent: 'space-between', marginRight: 20 }}>
                    <View>
                        <Text style={{ color: colors.Secondary, fontSize: 22, fontWeight: '700' }}>{allPositions[selectedIndex.current]?.trading_symbol}</Text>
                        <Text style={{ color: colors.Secondary, fontWeight: '400' }}>{moment(allPositions[selectedIndex.current]?.expiry).format('DDMMMYYYY')}</Text>
                    </View>
                    <View style={{ padding: 5 }}>
                        <Text style={{ color: colors.Secondary }}>L: {allPositions[selectedIndex.current]?.l}</Text>
                        <Text style={{ color: colors.Secondary }}>O: {allPositions[selectedIndex.current]?.o}</Text>
                    </View>
                    <View style={{ padding: 5 }}>
                        <Text style={{ color: colors.Secondary }}>H: {allPositions[selectedIndex.current]?.h}</Text>
                        <Text style={{ color: colors.Secondary }}>C: {allPositions[selectedIndex.current]?.c}</Text>
                    </View>
                </View>
                <View>
                    <View style={{ borderRadius: 5, alignItems: 'center' }}>
                        <Text style={{ color: colors.Secondary, fontWeight: '700', fontSize: 20 }}>LTP: {allPositions[selectedIndex.current]?.ltp}</Text>
                    </View>
                </View>
                <View style={{ marginLeft: 10, flexDirection: 'row', justifyContent: 'space-between', marginRight: 20, marginTop: 8 }}>
                    <Pressable style={{ borderWidth: 1, padding: 5, borderRadius: 5, width: '20%', alignItems: 'center', backgroundColor: marketType == 'MARKET' ? '#0084df' : '#b8e2ff' }} onPress={() => setMarketType('MARKET')}>
                        <Text style={{ color: marketType == 'MARKET' ? 'white' : 'black', fontWeight: '500' }}>Market</Text>
                    </Pressable>
                    <Pressable style={{ borderWidth: 1, padding: 5, borderRadius: 5, width: '20%', alignItems: 'center', backgroundColor: marketType == 'LIMIT' ? '#0084df' : '#b8e2ff' }} onPress={() => setMarketType('LIMIT')}>
                        <Text style={{ color: marketType == 'LIMIT' ? 'white' : 'black', fontWeight: '500' }}>Limit</Text>
                    </Pressable>
                    <Pressable style={{ borderWidth: 1, padding: 5, borderRadius: 5, width: '20%', alignItems: 'center', backgroundColor: marketType == 'STOPLOSS' ? '#0084df' : '#b8e2ff' }} onPress={() => setMarketType('STOPLOSS')}>
                        <Text style={{ color: marketType == 'STOPLOSS' ? 'white' : 'black', fontWeight: '500' }}>SL</Text>
                    </Pressable>
                    <Pressable style={{ borderWidth: 1, padding: 5, borderRadius: 5, width: '20%', alignItems: 'center', backgroundColor: marketType == 'INTRADAY' ? '#0084df' : '#b8e2ff' }} onPress={() => setMarketType('INTRADAY')}>
                        <Text style={{ color: marketType == 'INTRADAY' ? 'white' : 'black', fontWeight: '500' }}>Intraday</Text>
                    </Pressable>
                </View>
                {
                    marketType != 'INTRADAY' &&
                    <View style={{ flexDirection: 'row', marginLeft: 10, marginRight: 20, marginTop: 10, justifyContent: 'space-evenly' }}>

                        <View style={{ width: '31%' }}>
                            <Text style={{ color: colors.Secondary, marginBottom: 2, fontWeight: '400', textAlign: 'center' }}>Lot</Text>
                            <TextInput keyboardType='numeric' style={{ borderWidth: 1, width: '100%', borderRadius: 10, borderColor: 'grey', height: 40, textAlign: 'center', color: colors.Secondary, fontSize: 18, fontWeight: 'bold' }} value={lot} onChangeText={text => onChangingLot(text.replace(/[^0-9]/g, ''))} />
                        </View>
                        <View style={{ width: '31%' }}>
                            <Text style={{ color: colors.Secondary, marginBottom: 2, fontWeight: '400', textAlign: 'center' }}>Quatity</Text>
                            <TextInput keyboardType='numeric' style={{ borderWidth: 1, width: '100%', borderRadius: 10, borderColor: 'grey', height: 40, textAlign: 'center', color: colors.Secondary, fontSize: 18, fontWeight: 'bold' }} editable={selectedSegment == 'NFO' ? true : false} value={quantity} onChangeText={text => onChangingQuantity(text)} />
                        </View>
                        {
                            (marketType == 'LIMIT' || marketType == 'STOPLOSS') &&
                            <View style={{ width: '31%' }}>
                                <Text style={{ color: colors.Secondary, marginBottom: 2, fontWeight: '400' }}>Price</Text>
                                <TextInput keyboardType='numeric' style={{ borderWidth: 1, width: '100%', borderRadius: 10, borderColor: 'grey', height: 40, color: colors.Secondary, textAlign: 'center', fontSize: 18, fontWeight: 'bold' }} value={price} onChangeText={text => {
                                    setPrice(text)
                                    var data = (text / allPositions[selectedIndex.current].tick_size).toFixed(2) % 1 == 0
                                    {
                                        data == false &&
                                            Toast.show({
                                                type: 'error',
                                                text1: `Price Should be in multiple of ${allPositions[selectedIndex.current]?.tick_size}`,
                                            });
                                    }
                                }} />
                            </View>
                        }
                    </View>
                }
                <View style={{ flexDirection: 'row', marginLeft: 10, marginRight: 20, marginTop: 10, justifyContent: 'space-evenly' }}>

                </View>
                {
                    marketType != 'INTRADAY' &&
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 8 }} >

                        {
                            selectedData?.net_qty > 0 &&
                            <Pressable style={{ backgroundColor: '#FF033E', width: '40%', height: 60, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}
                                disabled={buttonLoader}
                                onPress={() => {
                                    if (checkShowModal) {
                                        selectedTrade.current = 'SELL'
                                        Keyboard.dismiss()
                                        tradeSheet.current.close()
                                        onSubmit('SELL')
                                    } else {
                                        console.log("here")
                                        selectedTrade.current = 'SELL'
                                        Keyboard.dismiss()
                                        tradeSheet.current.close()
                                        setModalVisibleTrade(true)
                                    }
                                }}>
                                {
                                    (marketType == 'LIMIT' || marketType == 'STOPLOSS') ?
                                        <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>SELL</Text>
                                        :
                                        <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>SELL @ {allPositions[selectedIndex.current]?.b}</Text>
                                }
                            </Pressable>
                        }
                        {
                            selectedData?.net_qty < 0 &&
                            <Pressable style={{ backgroundColor: '#1034A6', width: '40%', height: 60, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}
                                disabled={buttonLoader}
                                onPress={() => {
                                    if (checkShowModal) {
                                        selectedTrade.current = 'BUY'
                                        Keyboard.dismiss()
                                        onSubmit('BUY')
                                        tradeSheet.current.close()
                                    } else {
                                        selectedTrade.current = 'BUY'
                                        Keyboard.dismiss()
                                        tradeSheet.current.close()
                                        setModalVisibleTrade(true)
                                    }

                                }}>
                                {buttonLoader && <ActivityIndicator size="small" color="#000" style={{ marginRight: 20 }} />}
                                {
                                    (marketType == 'LIMIT' || marketType == 'STOPLOSS') ?
                                        <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>BUY</Text>
                                        :
                                        <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>BUY @ {allPositions[selectedIndex.current]?.a}</Text>

                                }
                            </Pressable>
                        }
                    </View>
                }
                {
                    marketType == 'INTRADAY' &&
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '10%' }} >
                        <Pressable style={{ backgroundColor: '#1034A6', width: '40%', height: 60, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} onPress={() => {
                            if (checkShowModal) {
                                selectedTrade.current = 'BUY'
                                Keyboard.dismiss()
                                onSubmit('BUY')
                            } else {
                                selectedTrade.current = 'BUY'
                                Keyboard.dismiss()
                                refRBSheet.current.close()
                                setModalVisible('true')
                            }
                        }}>
                            {buttonLoader && <ActivityIndicator size="small" color="white" style={{ marginRight: 20 }} />}
                            <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>Submit</Text>
                        </Pressable>
                    </View>
                }
            </RBSheet>
            <Modal animationType="slide" transparent={true} visible={modalVisibleTrade}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View >
                            <View style={{ backgroundColor: 'white', borderRadius: 20 }}>
                                {/* <Pressable style={{ justifyContent: 'flex-end', alignSelf: 'flex-end', marginBottom: 10, marginTop: 18 }} onPress={() => setModalVisible(!modalVisible)}>
                                <Image source={crossIcon} style={styles.crossLogo} />
                            </Pressable> */}
                                <Text style={{ textAlign: 'center', fontSize: 15, color: colors.Secondary, fontWeight: '600', marginTop: 20 }}>Are you sure you want to place order.</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <Pressable style={styles.cancelButton} onPress={() => {
                                        // setMarketType('MARKET')
                                        // setPrice('')
                                        // setQuantity('')
                                        // setLot('')
                                        refRBSheet.current.open()
                                        setModalVisibleTrade(!modalVisibleTrade)
                                    }}>
                                        <Text style={styles.cancelText}>No</Text>
                                    </Pressable>
                                    <Pressable onPress={() => onSubmit(selectedTrade.current)} style={[styles.continueButton, { backgroundColor: selectedTrade.current == 'BUY' ? '#1034A6' : '#FF033E' }]}>
                                        <Text style={styles.continueText}>{marketType == 'INTRADAY' ? 'Yes' : selectedTrade.current}</Text>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    )
}

export default Positions;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 45,
        justifyContent: 'space-between'
    },
    positionText: {
        marginLeft: 20,
        fontSize: 17,
        fontWeight: '500'
    },
    amount: {
        fontSize: 18,
    },
    toogleView: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10
    },
    dropdownView: {
        marginTop: 20,
        marginLeft: 22,
        marginRight: 22,
    },
    toogle: {
        height: 24,
        width: 24,
        borderRadius: 12,
        borderWidth: 2,
        borderColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
    },
    toogleFill: {
        height: 12,
        width: 12,
        borderRadius: 6,
        backgroundColor: 'white',
    },
    toogleText: {
        color: 'white',
        fontSize: 14,
        marginLeft: 10,
        marginRight: 50
    },
    tabView: {
        flexDirection: 'row',
        // marginLeft: 22,
        // marginRight: 22,
        // marginTop: 10,
        backgroundColor: '#b8e2ff'
    },
    tab: {
        width: '50%',
        alignItems: 'center',
        borderBottomWidth: 1,
        paddingBottom: 8
    },
    tabText: {
    },
    item: {
        marginTop: 1,
        borderRadius: 5,
        padding: 10
    },
    tradeName: {
        fontSize: 18,
        fontWeight: '700'
    },
    tradeQty: {
        color: 'green',
        fontSize: 18
    },
    nameView: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    priceView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5
    },
    avgPrice: {
        fontSize: 14,
        fontWeight: 500
    },
    rate: {
        fontSize: 14
    },
    price: {
        color: 'green',
    },
    segmentTabView: {
        flexDirection: 'row',
        marginLeft: 22,
        marginRight: 22,
        marginTop: 10
    },
    segementTab: {
        width: '50%',
        alignItems: 'center',
        borderWidth: 1,
        padding: 3,
        borderRadius: 5,
        marginLeft: 5
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    filterIcon: {
        height: 25,
        width: 25,
        marginTop: 3,
        marginRight: 10
    },
    headerIcon: {
        flexDirection: 'row'
    },
    input: {
        borderRadius: 12,
        width: Width * 0.5,
        paddingLeft: 15,
        bottom: 6
    },
    dateInput: {
        borderRadius: 12,
        width: Width * 0.9,
        height: 45,
        paddingLeft: 15,
        bottom: 6,
        marginTop: 10,
        justifyContent: 'center'
    },
    label: {
        color: color1,
        fontSize: 15,
        fontFamily: 'Roboto-Regular',
        fontWeight: '600',
        marginTop: 10
    },
    cancelButton: {
        borderRadius: 5,
        marginTop: 18,
        marginLeft: 10,
        borderWidth: 1
    },
    cancelText: {
        fontSize: 16,
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15
    },
    continueButton: {
        borderRadius: 5,
        marginTop: 18,
        marginLeft: 10,
    },
    continueText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'white',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15
    },
    searchIcon: {
        // bottom: 5
        height: 32,
        width: 32
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
})